@import 'style/style.module';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $dark;
  height: 80px;
  @media only screen and (max-width: 767px) {
    height: 60px;
  }
  .mainContainer {
    @include mainContainer;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &_toggle {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      position: relative;
      z-index: 101;
      background: $mdark;
      cursor: pointer;
      user-select: none;
      transform: scale(1);
      margin-right: 15px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: inherit;
        background: $grad;
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &:hover:before {
        opacity: 1;
      }
    }
  }
  .activeToggle {
    @extend .header_toggle;
    .line {
      &:first-child {
        transform: translate3d(0, 4px, 0) rotate(-135deg);
      }
      &:last-child {
        transform: translate3d(0, -4px, 0) rotate(135deg);
      }
    }
  }
  &_logo {
    flex: 0 0 auto;
    width: 187px;
    height: 30px;
    cursor: pointer;
    background: url(/images/logo.svg) no-repeat 0 50%;
    background-size: contain;
    position: relative;
    z-index: 101;
    @media only screen and (max-width: 767px) {
      width: 155px;
      height: 25px;
    }
  }
  &_left {
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    .right_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 151px;
      .line_2 {
        content: '';
        height: 16px;
        top: 0;
        bottom: 0;
        left: 20px;
        width: 1.5px;
        margin: 0.9rem 0;
        background: $grey;
      }
    }
    &_nav {
      display: flex;
      align-items: center;
      &_link {
        color: $grey;
        align-items: center;
        gap: 9px;
        padding: 8px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.45;
        cursor: pointer;
        display: inline-flex;
        vertical-align: top;
        transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative;
        &:hover {
          color: $white;
          svg {
            fill: $white;
          }
        }
        svg {
          fill: $grey;
          width: 20px;
          height: 24px;
          transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:nth-child(4) {
          svg {
            width: 15px;
            height: 14px;
          }
        }
        .list {
          padding: 17px 0;
          display: flex;
          flex-direction: column;
          height: auto;
          margin: 0;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          transition-property: transform, opacity, visibility;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          background: $mdark;
          box-shadow: 0 6px 22px rgb(93 98 109 / 10%);
          border-radius: 20px;
          opacity: 0;
          visibility: hidden;
          .listItem {
            padding: 13px 58px 13px 25px;
            white-space: nowrap;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 15px;
            svg {
              transform: rotate(0);
              width: 35px;
              height: 36px;
            }
            .textWrapper {
              .itemTitle {
                font-weight: 600;
                font-size: 14px;
              }
              .itemSubTitle {
                color: $grey;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.4;
                margin: 2px 0 0 0;
              }
            }
            &:after {
              position: absolute;
              right: 23px;
              content: '';
              flex: 0 0 auto;
              background: url(/img/menu/arrow-sub.svg) no-repeat 50% 50%;
              background-size: contain;
              width: 8px;
              height: 12px;
              margin-left: 10px;
              transition-property: transform, opacity;
              transition-duration: 0.2s;
              transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              transform: translate3d(-10px, 0, 0);
              opacity: 0;
            }
          }
          .listItemActive {
            opacity: 1;
          }
          .listItemNotActive {
            opacity: 0.5;
          }
        }
      }
      .active {
        @extend .header_left_nav_link;
        color: $white;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: $grad;
          margin: 10px 0;
          mix-blend-mode: darken;
        }
      }
      .openActive {
        @extend .header_left_nav_link;
        position: relative;
        svg {
          transform: rotate(180deg);
        }
        &:nth-child(4) {
          svg {
            transform: rotate(0);
          }
        }
        .list {
          transform: translate3d(-50%, 7px, 0);
          opacity: 1;
          visibility: visible;
          transition-property: transform, opacity, visibility;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          .listItem:hover {
            &:after {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
    }
    &_login {
      position: relative;
      &_toggle {
        cursor: pointer;
        text-align: center;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 40px;
        line-height: 40px;
        border-radius: 12px;
        padding: 15px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        background: $mdark;
        position: relative;
        &:active {
          transform: scale(0.95);
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }

        &:hover:after {
          opacity: 1;
          background: $grad;
        }
        span {
          position: relative;
          z-index: 5;
        }
      }
      &_toggleOpen {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 1;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }
      }

      &_sub {
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 10px;
        background: $mdark;
        box-shadow: 0 6px 22px rgb(93 98 109 / 10%);
        border-radius: 14px;
        margin: 10px 0 0 0;
        min-width: 150px;
        transition-property: transform, opacity, visibility;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateY(0px);
        opacity: 0;
        visibility: hidden;
        .toggleItem {
          display: block;
          font-size: 14px;
          border-radius: 10px;
          padding: 10px;
          flex: 0 0 auto;
          font-weight: 600;
          line-height: 1.45;
          cursor: pointer;
          a {
            text-decoration: none;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
        }
        .toggleItemActive {
          @extend .toggleItem;
          background-color: rgba(255, 255, 255, 0.1);
          color: $white;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
      &_subOpen {
        transform: translateY(7px);
        transition-duration: 0.2s;
        opacity: 1;
        visibility: visible;
      }
    }
    &_lang {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_toggle {
        cursor: pointer;
        text-align: center;
        color: $white;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        background: $mdark;
        border-radius: 10px;
        position: relative;
        &:active {
          transform: scale(0.95);
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }

        &:hover:after {
          opacity: 1;
          background: $grad;
        }
        span {
          position: relative;
          z-index: 5;
        }
      }
      &_toggleOpen {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          background: $grad;
          opacity: 1;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          z-index: 4;
        }
      }

      &_sub {
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 10px;
        background: $mdark;
        box-shadow: 0 6px 22px rgb(93 98 109 / 10%);
        border-radius: 14px;
        margin: 10px 0 0 0;
        min-width: 150px;
        transition-property: transform, opacity, visibility;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateY(0px);
        opacity: 0;
        visibility: hidden;
        .toggleItem {
          display: block;
          font-size: 14px;
          border-radius: 10px;
          padding: 10px;
          flex: 0 0 auto;
          font-weight: 600;
          line-height: 1.45;
          cursor: pointer;
          a {
            text-decoration: none;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
        }
        .toggleItemActive {
          @extend .toggleItem;
          background-color: rgba(255, 255, 255, 0.1);
          color: $white;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
      &_subOpen {
        transform: translateY(7px);
        transition-duration: 0.2s;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .leftMobile {
    display: none;
    height: 100%;

    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      position: fixed;
      height: 100%;
      background: black;
      top: 0;
      left: 0;
      padding: 85px 20px 35px 20px;
      margin: 0;
      .top {
        display: flex;
        flex-direction: column;
        .nav {
          color: $grey;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .link {
            padding: 10px 0;
            font-weight: bold;
            font-size: 24px;
            line-height: 1.3;
            &:last-child {
              padding-bottom: 0;
            }
          }
          .openActive {
            color: $white;
            .list {
              display: flex;
              flex-direction: column;
              margin-top: 15px;
              padding: 10px 30px 10px;
              border-left: 1px solid $borders;
              transition: 0.4s linear;
              .listLink {
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 145%;
                margin-bottom: 25px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .lang {
          margin-top: 30px;
          .sub {
            font-weight: 600;
            font-size: 18px;
            line-height: 145%;
            color: $grey;
            height: 100%;
            opacity: 1;
            visibility: visible;
            .toggleItem {
              padding-right: 10px;
            }
            .toggleItemActive {
              color: $white;
            }
          }
        }
      }

      .follow {
        justify-items: flex-end;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $grey;
        text-transform: uppercase;
        .social {
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          .icon {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $mdark;
            width: 50px;
            height: 50px;
            border-radius: 14px;
            transform: scale(1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            color: $white;
            text-decoration: none;
            z-index: 2;
            &:active {
              transform: scale(0.95);
            }
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: $grad;
              opacity: 0;
              transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }
            svg {
              position: relative;
              z-index: 5;
            }
            &:hover:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.productChanger {
  display: flex;
  align-items: center;
  margin-left: 15px;
  position: relative;
  pointer-events: none;

  & > svg {
    display: none;
  }

  @media only screen and (min-width: 1020px) {
    pointer-events: all;

    & > svg {
      display: block;
    }
  }

  & > svg {
    margin-left: 12px;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.logo {
  display: flex;
  align-items: center;
  width: 100% !important;
  margin-left: 60px;
  @media only screen and (min-width: 1020px) {
    margin: 0;
  }
}

.productChangerTitle {
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #fff;
  font-family: 'Gilroy';
}

.productList {
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  gap: 25px;
  background: #1d192c;
  box-shadow: 0px 6px 22px rgba(93, 98, 109, 0.1);
  border-radius: 20px;
  width: 324px;
}

.listItem {
  display: flex;
  align-items: center;

  & > svg {
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
}

.textWrapper {
  font-family: 'Gilroy';
  opacity: 0.5;
  margin-left: 15px;

  &Active,
  &:hover {
    opacity: 1;
  }
}

.itemTitle {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  display: block;
  color: #ffffff;
}

.itemSubTitle {
  display: block;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #a29dba;
}

.productChangerActive {
  & > svg {
    transform: rotate(180deg);
  }
}

.link {
  & > a,
  span {
    font-family: 'Gilroy';
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 145% !important;
    color: #a29dba !important;
    opacity: 1 !important;

    &:hover {
      color: #fff !important;
    }

    @media only screen and (min-width: 1020px) {
      font-size: 14px !important;
    }
  }
}

.linkActive {
  & > a {
    color: #ffffff !important;
  }
}

.downloadLink {
  padding: 15px;
  gap: 10px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  color: #0d0d11;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: none;
  font-size: 14px !important;

  @media only screen and (min-width: 1020px) {
    display: flex;
  }
}

.langChanger {
  width: 40px;
  height: 40px;
  background: #1d192c;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #fff;
  padding: 0 !important;
  position: relative;
  margin: 0 !important;

  @media only screen and (min-width: 1020px) {
    &:after {
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      left: calc(40px + 1.5rem);
      content: '';
      height: 15px;
      width: 1px;
    }
  }
}

.logInLink {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 15px;
  width: 80px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  font-family: 'Gilroy';
  color: #ffffff;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 3.125rem;

  @media only screen and (min-width: 1020px) {
    display: flex;
  }
}

.mobileProductLink {
  @media only screen and (min-width: 1020px) {
    display: none;
  }

  &Active {
    & > span {
      color: #fff !important;
    }
  }
}

.mobileProducts {
  display: flex !important;
  flex-direction: column;
  margin-top: 15px;
  padding: 10px 30px;
  border-left: 1px solid #2e2942;
  transition: 0.4s linear;
}

.mobileProductsItem {
  text-decoration: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 25px;
  opacity: 1 !important;

  &:last-of-type {
    margin: 0;
  }
}

.soc {
  justify-content: flex-start;
}

.fallbackMain {
  background-image: url('/images/menu/fallback-main.png');
  background-size: 35px 35px;
}
